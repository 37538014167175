import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { lazyload } from "@cloudinary/react"
import { fill } from "@cloudinary/url-gen/actions/resize"
import * as s from "./our-culture.module.css"

const OurCulture = ({ sendata }) => {
  const d = sendata

  const linkUrl = d.homePageCultureCtaUrl.url.replace(
    "https://content.waracle.com",
    ""
  )

  const boxRef = useRef()

  const [aniBoxStart, setAniBoxStart] = useState()
  const [aniBoxEnd, setAniBoxEnd] = useState()

  const getPosition = () => {
    setAniBoxStart(boxRef.current.offsetTop - 600)
    setAniBoxEnd(boxRef.current.offsetTop - 150)
  }

  useEffect(() => {
    getPosition()
  }, [])

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const imgUrl = d.homePageCultureImage.mediaDetails.file
  const imgAlt = d.homePageCultureImage.altText

  let theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUrl}`)

  theImage.quality("70")
  theImage.resize(fill().width(700))
  theImage.format("auto")

  return (
    <div className="position-relative border-bottom border-top" ref={boxRef}>
      <div className="container pt-6 pb-6">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="col col-lg-9">
              <span className="text-uppercase d-block mb-4">
                {d.homePageCultureTitle}
              </span>
              <h2 className="mb-5">{d.homePageCultureIntro}</h2>
              <Link
                to={linkUrl}
                title={d.homePageCultureCtaLinkText}
                className="ani_grey_arrow"
              >
                {d.homePageCultureCtaLinkText}
              </Link>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-5 mt-lg-0">
            <Parallax
              speed={5}
              translateY={[35, 0]}
              startScroll={aniBoxStart}
              endScroll={aniBoxEnd}
            >
              <div>
                <AdvancedImage
                  cldImg={theImage}
                  plugins={[
                    lazyload({
                      rootMargin: "250px",
                      threshold: 0.25,
                    }),
                  ]}
                  alt={imgAlt}
                  className="mw-100"
                />
              </div>
            </Parallax>
          </div>
        </div>
      </div>
      <div className={`d-none d-lg-block ${s.background_blue}`}></div>
    </div>
  )
}

export default OurCulture
