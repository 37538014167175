import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import * as s from "./what-we-do.module.css"
import { cloudImage } from "../utils.js"

const WhatWeDo = props => {
  const boxRef = useRef()
  const [aniBoxStart, setAniBoxStart] = useState()
  const [aniBoxEnd, setAniBoxEnd] = useState()

  const getPosition = () => {
    setAniBoxStart(boxRef.current.offsetTop - 675)
    setAniBoxEnd(boxRef.current.offsetTop - 350)
  }

  useEffect(() => {
    getPosition()
  }, [])

  const {
    homePageInfoBlockSectionTitle,
    homePageInfoBlockSectionIntro,
    homePageInfoBlockSectionLinkText,
    homePageInfoBlockSectionLinkUrl,
    homePageInfoBlocks,
  } = props.sendata

  const wwdBlockTitle = homePageInfoBlockSectionTitle
  const wwdBlockIntro = homePageInfoBlockSectionIntro

  const wwdBlockLinkText = homePageInfoBlockSectionLinkText || "Discover More"

  var wwdBlockLinkUrl = homePageInfoBlockSectionLinkUrl

  if (wwdBlockLinkUrl !== null) {
    wwdBlockLinkUrl = wwdBlockLinkUrl.url.replace(
      "https://content.waracle.com",
      ""
    )
  } else {
    wwdBlockLinkUrl = "/what-we-do/"
  }

  const wwdCards = homePageInfoBlocks || new Array(4).fill(null)

  const cardBlock = wwdCards.map((aCard, index) => {
    let speed = 5
    let translate = 0

    if (index === 1) {
      translate = 15
    }

    if (index === 2) {
      translate = 30
    }

    if (index === 3) {
      translate = 7.5
    }

    let linkUrl = aCard.homePageInfoBlockLinkUrl.url.replace(
      "https://content.waracle.com",
      ""
    )

    // images

    const bgImg = aCard.homePageInfoBlockImage.mediaDetails.file
    const bgImgURL = cloudImage(
      bgImg,
      aCard.homePageInfoBlockImage.mimeType,
      500,
      true
    )

    return (
      <div className="col-12 col-md-6 col-xl-3 mb-3" key={index}>
        <Parallax
          speed={speed}
          translateY={[translate, 0]}
          startScroll={aniBoxStart}
          endScroll={aniBoxEnd}
        >
          <div
            className={`bg_image color-white ${s.ani_show_content} text_center`}
            style={{ backgroundImage: bgImgURL }}
          >
            <div
              className={`col-12 p-lg-4 p-3 z_index_top ${s.center_absolute} center_absolute add_transition`}
            >
              <h2 className="h3">{aCard.homePageInfoBlockTitle}</h2>
              <div className={s.show_more}>
                <p>{aCard.homePageInfoBlockIntro}</p>
                <Link
                  to={linkUrl}
                  title={aCard.homePageInfoBlockLinkText}
                  className="ani-underline color-white"
                >
                  {aCard.homePageInfoBlockLinkText}
                </Link>
              </div>
            </div>
            <div className="dark_image_overly"></div>
          </div>
        </Parallax>
      </div>
    )
  })

  return (
    <>
      <div className="container mb-5 pt-5 mt-5" ref={boxRef}>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-11 text_center">
            <span className="text-uppercase d-block mb-4">{wwdBlockTitle}</span>
            <h2
              className={s.br_lg}
              dangerouslySetInnerHTML={{ __html: wwdBlockIntro }}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gx-3 mb-5 color-white text_center what_we_do">
          {cardBlock}
        </div>
      </div>
      <div className="container mb-100">
        <div className="row">
          <div className="col-12 text_center">
            <Link
              to={wwdBlockLinkUrl}
              title="Discover More about our services"
              className="ani_grey_arrow"
            >
              {wwdBlockLinkText}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhatWeDo
