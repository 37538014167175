import React from "react"
import { Link } from "gatsby"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import * as clientLogosStyle from "./client-logos.module.css"

const ClientLogos = ({ sendata }) => {
  const d = sendata

  const theIntroPara =
    d.homePageClientLogosIntroPara ||
    "We live and breathe our three chosen verticals. Our product teams work for some of the UK's most innovative businesses."
  const listLogos = d.homePageClientsTheLogos.map((theLogo, index) => {
    const cld = new Cloudinary({
      cloud: {
        cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
      },
      url: {
        analytics: false,
      },
    })

    let theImgUrl = theLogo.homePageClientsAClientLogo.mediaDetails.file
    let altText = theLogo.homePageClientsAClientLogo.altText

    let theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${theImgUrl}`)

    theImage.format("auto")

    if (index === 5 || index === 11) {
      return (
        <div
          key={index}
          className="col-6 col-md-4 col-lg-3 col-xl-2 mb-5 text-center text-md-start text-xl-end"
        >
          <AdvancedImage
            cldImg={theImage}
            alt={altText}
            className={`${clientLogosStyle.client_logo} mb-0 mb-lg-5`}
          />
        </div>
      )
    }
    if (index === 0 || index === 6) {
      return (
        <div
          key={index}
          className="col-6 col-md-4 col-lg-3 col-xl-2 mb-5 text-center text-md-start text-xl-start"
        >
          <AdvancedImage
            cldImg={theImage}
            alt={altText}
            className={`${clientLogosStyle.client_logo} mb-0 mb-lg-5`}
          />
        </div>
      )
    } else {
      return (
        <div
          key={index}
          className="col-6 col-md-4 col-lg-3 col-xl-2 mb-5 text-center text-md-start text-xl-center"
        >
          <AdvancedImage
            cldImg={theImage}
            alt={altText}
            className={`${clientLogosStyle.client_logo} mb-0 mb-lg-5`}
          />
        </div>
      )
    }
  })

  return (
    <div className="grey_bg mb-5 pt-6 pb-6">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-9 color-white mb-100">
            <h2>{theIntroPara}</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center gx-0 gx-lg-5">{listLogos}</div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Link
              to="/our-work/"
              title="Explore our work"
              className="ani_white_arrow"
            >
              Explore Our Work
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientLogos
