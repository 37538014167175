import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import * as s from "./industry-boxes.module.css"

const IndustryBoxes = ({ sendata }) => {
  const d = sendata

  const linkUrl = d.homePageIndustriesSectionLinkUrlCopy.url.replace(
    "https://content.waracle.com",
    ""
  )

  const boxRef = useRef()
  const [aniBoxStart, setAniBoxStart] = useState()
  const [aniBoxEnd, setAniBoxEnd] = useState()

  const getPosition = () => {
    setAniBoxStart(boxRef.current.offsetTop - 475)
    setAniBoxEnd(boxRef.current.offsetTop - 150)
  }

  useEffect(() => {
    getPosition()
  }, [])

  const indCards = d.homePageInfoBlocksIndustries || new Array(4).fill(null)

  const cardBlock = indCards.map((aCard, index) => {
    let speed = 5
    let translate = 30

    if (index === 1) {
      translate = 0
    }

    if (index === 2) {
      speed = 2.5
    }

    let colNumbs

    if (indCards.length == 3) {
      colNumbs = "col-12 col-lg-4"
    }

    if (indCards.length == 4) {
      colNumbs = "col-12 col-md-6 col-lg-3"
    }

    let linkUrl = aCard.homePageInfoBlockLinkUrlIndustries.url.replace(
      "https://content.waracle.com",
      ""
    )

    // image

    const bgImg = aCard.homePageInfoBlockImageIndustries.mediaDetails.file

    // return industry blocks
    return (
      <div className={`${colNumbs} mb-3`} key={index}>
        <Parallax
          speed={speed}
          translateY={[translate, 0]}
          startScroll={aniBoxStart}
          endScroll={aniBoxEnd}
        >
          <div
            className={`bg_image color-white ${s.ani_show_content} text_center`}
            style={{
              backgroundImage: `url(https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto,c_fill,w_700,h_800,q_70,g_face/img/${bgImg})`,
            }}
          >
            <div
              className={`col-12 p-lg-5 p-4 z_index_top ${s.center_absolute} center_absolute add_transition`}
            >
              <h2>{aCard.homePageInfoBlockTitleIndustries}</h2>
              <div className={s.show_more}>
                <p>{aCard.homePageInfoBlockIntroIndustries}</p>
                <Link
                  to={linkUrl}
                  title={aCard.homePageInfoBlockLinkTextIndustries}
                  className="ani-underline color-white"
                >
                  {aCard.homePageInfoBlockLinkTextIndustries}
                </Link>
              </div>
            </div>
            <div className="dark_image_overly"></div>
          </div>
        </Parallax>
      </div>
    )
  })

  // return main component
  return (
    <>
      <div className="container mb-5 pt-5" ref={boxRef}>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-11 text_center">
            <span className="text-uppercase d-block mb-4">
              {d.homePageIndustriesTitle}
            </span>
            <h2
              dangerouslySetInnerHTML={{ __html: d.homePageIndustriesIntro }}
            />
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row color-white">{cardBlock}</div>
      </div>
      <div className="container mb-100">
        <div className="row">
          <div className="col-12 text_center">
            <Link
              to={linkUrl}
              title={d.homePageIndustriesSectionLinkTextCopy}
              className="ani_grey_arrow"
            >
              {d.homePageIndustriesSectionLinkTextCopy}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndustryBoxes
