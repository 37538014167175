import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { Parallax } from "react-scroll-parallax"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { lazyload } from "@cloudinary/react"
import { fill } from "@cloudinary/url-gen/actions/resize"
import * as ourThoughtsStyle from "./our-thoughts.module.css"

const OurThoughts = props => {
  const { thoughtsBlogItem1 } = props.pagedata

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const boxRef = useRef()

  const [aniBoxStart, setAniBoxStart] = useState()
  const [aniBoxEnd, setAniBoxEnd] = useState()

  const getPosition = () => {
    setAniBoxStart(boxRef.current.offsetTop - 600)
    setAniBoxEnd(boxRef.current.offsetTop - 200)
  }

  useEffect(() => {
    getPosition()
  }, [])

  // dark-light mode

  let mode = ""

  if (props.reversed === "true") {
    mode = `grey_bg pt-5
            ${ourThoughtsStyle.reversed}`
  }

  // default props

  OurThoughts.defaultProps = {
    reversed: "false",
  }

  const swiperItems = thoughtsBlogItem1.map((thoughtsBlogItem, index) => {
    const slug = thoughtsBlogItem.uri
    const title = thoughtsBlogItem.title
    const altText = thoughtsBlogItem.featuredImage.node.altText
    let imgUri = thoughtsBlogItem.featuredImage.node.mediaDetails.file

    const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

    theImage.quality("70")
    theImage.resize(fill().width(412).height(250))
    theImage.format("auto")

    let doParallax = 15

    if (index === 1) {
      doParallax = 0
    }

    return (
      <SwiperSlide
        key={index}
        className={`${ourThoughtsStyle.swiper_slide} swiper-slide`}
      >
        <Parallax
          speed={5}
          translateY={[doParallax, 0]}
          startScroll={aniBoxStart}
          endScroll={aniBoxEnd}
          className="h-100"
        >
          <div className="h-100 align-content-between d-flex flex-column">
            <Link to={slug} title={`Check out: ${title}`}>
              <AdvancedImage
                cldImg={theImage}
                plugins={[
                  lazyload({
                    rootMargin: "0px",
                    threshold: 0.25,
                  }),
                ]}
                alt={altText}
                className={`mw-100`}
              />
            </Link>
            <Link to={slug} title={`Check out: ${title}`}>
              <h3 className="h4 mt-3 mb-5 flex-grow-1">{title}</h3>
            </Link>
          </div>
        </Parallax>
      </SwiperSlide>
    )
  })

  return (
    <>
      <div className={mode}>
        <div className="container mt-3 pt-0 pb-5 pt-md-3 pt-lg-5`" ref={boxRef}>
          <div className="row mb-2 mb-md-4">
            <div className="col-12 col-lg-6">
              <h2>Thought-provoking ideas from our thinkers</h2>
            </div>
            <div className="col-6 d-none d-lg-flex justify-content-end align-items-center">
              <Link
                to="/insights/"
                title="All Insights"
                className="ani_grey_arrow right_aligned"
              >
                All Insights
              </Link>
            </div>
          </div>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={20}
            breakpoints={{
              767: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className={`${ourThoughtsStyle.hp_thoughts}
                            hp_thoughts`}
          >
            {swiperItems}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default OurThoughts
