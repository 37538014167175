import React from "react"
import { Link } from "gatsby"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
//import * as clientLogosStyle from "./client-logos.module.css"

const UpcomingEvent = ({ sendata }) => {
  let events = sendata
  let eventCount = 1

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  if (events === undefined || events === null) {
    events = []
  }

  return (
    <>
      {events.map((event, index) => {
        const isHidden = event.eventsHideInListinngs || false
        if (isHidden !== true && eventCount === 1) {
          eventCount++
          let slug = event.featuredImage.node.mediaDetails.file
          let altText = event.featuredImage.node.altText
          let theImage = cld.image(
            `${process.env.GATSBY_API_FOLDER_URL}${slug}`
          )

          theImage.resize(fill().width(600).height(400))
          theImage.format("auto")
          return (
            <div className="grey_bg pt-6 pb-6" key={index}>
              <div className="container">
                <div className="row"></div>
                <div className="col-12 color-white">
                  <h2>Upcoming Event</h2>
                </div>
                <div className="row gx-lg-5 mt-4 align-content-start align-content-xl-center color-white">
                  <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <AdvancedImage
                      cldImg={theImage}
                      alt={altText}
                      className={`w-100`}
                      style={{ border: "1px solid white" }}
                    />
                  </div>
                  <div
                    className="col-12 col-md-8"
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h3 className="lower mb-2 mt-4 mt-lg-0">
                      <Link to={event.uri} title="Event registration">
                        {event.title}
                      </Link>
                    </h3>
                    <ul className="list-unstyled m-0">
                      <li className="mb-3">
                        <img
                          className="inline-icon-width"
                          src="/assets/img/icon_location_white.svg"
                          alt="location icon"
                        />
                        Location: {event.events.eventsPhysicalLocation}
                      </li>
                      <li className="mb-3">
                        <img
                          className="inline-icon-width"
                          src="/assets/img/icon-calendar-white.svg"
                          alt="date icon"
                        />
                        Kicks off: {event.events.eventsDateTime}
                      </li>
                      <li className="mb-0">
                        <img
                          className="inline-icon-width"
                          src="/assets/img/icon-clock-white.svg"
                          alt="clock icon"
                        />
                        Duration: {event.events.eventsDuration}
                      </li>
                    </ul>
                    <div className="my-3">
                      <Link
                        to={event.uri}
                        className="ani_grey_arrow d-inline-block"
                        title="Event registration"
                      >
                        <span>Information & </span>
                        {event.events.eventsType === "standard" && (
                          <span>Registration</span>
                        )}
                        {event.events.eventsType === "wda" && (
                          <span>Application</span>
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })}
    </>
  )
}

export default UpcomingEvent
