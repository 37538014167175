import React from "react"
import { Link } from "gatsby"
import { isSafariLess16, cloudImage } from "../utils.js"
import * as style from "./hero.module.css"

const HomeHero = ({ sendata }) => {
  const d = sendata
  const isSafariV16 = isSafariLess16()

  var theTagline = d.homePageHeroTagline

  theTagline = theTagline.replace(/<p>/g, "")
  theTagline = theTagline.replace(/<\/p>/g, " ")

  var theByline = d.homePageHeroByline

  theByline = theByline.replace(/<p>/g, "")
  theByline = theByline.replace(/<\/p>/g, ' <br class="d-none d-xl-block"/>')

  const bgImgURL = cloudImage(
    d.homePageHeroImage.mediaDetails.file,
    d.homePageHeroImage.mimeType,
    1500,
    true
  )

  return (
    <div
      className={`${style.bg_image} bg_image d-flex justify-content-center align-items-center grey_bg overflow-hidden`}
      style={{
        height: "100vh",
        minHeight: "450px",
        backgroundImage: bgImgURL,
      }}
    >
      <div className="container">
        <div
          id="hide-row-when-nav-open"
          className={`row ${style.allow_strong}`}
        >
          <div className="col-12 col-lg-8 color-white z_index_top home-header animated-headline">
            <div className="home-delay animated-headline-wrap">
              <h1 className="new_style">
                <span
                  dangerouslySetInnerHTML={{ __html: theTagline }}
                  className={`new_style_addit ${
                    isSafariV16 ? "safari_16" : ""
                  }`}
                />
              </h1>
              <span
                dangerouslySetInnerHTML={{ __html: theByline }}
                className={`d-block header-fade-in ${style.byline}`}
              />
            </div>
            <Link
              to={d.homePageHeroCtaLink}
              style={{
                display: "block",
                marginTop: "45px",
              }}
            >
              <button
                title={d.homePageHeroCtaText}
                className="ani_grey_arrow fade-down right_aligned h5"
                style={{
                  background: "none",
                  border: "0",
                  color: "white",
                  paddingLeft: "0",
                }}
              >
                {d.homePageHeroCtaText}
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className={`dark_image_overly`}></div>
    </div>
  )
}

export default HomeHero
