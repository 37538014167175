import React from "react"
import { Link } from "gatsby"
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import * as s from "./client-slider.module.css"
import { Parallax } from "react-scroll-parallax"
import { cloudImage } from "../utils.js"

const ClientSlider = ({ sendata }) => {
  const d = sendata

  return (
    <Swiper
      modules={[Pagination]}
      pagination={{ clickable: true }}
      className={`${s.hp_full_swiper} hp_full_swiper`}
    >
      {sendata.homePageClientSlides.map((slide, index) => {
        let title = slide.homePageClientTitle
        let subText = slide.homePageClientSubtext
        let linkText = slide.homePageClientLinkText
        let link = slide.homePageClientClientLink.url.replace(
          "https://content.waracle.com",
          ""
        )
        let bgImg = cloudImage(
          slide.homePageClientImage.mediaDetails.file,
          "jpg",
          1600,
          true
        )

        return (
          <SwiperSlide
            key={index}
            className={`${s.swiper_slide} swiper-slide d-flex align-items-end align-items-md-center`}
          >
            <Parallax
              translateX={[5, 0]}
              translateY={[0, 0]}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            >
              <div
                className={s.bg_imgs}
                style={{
                  backgroundImage: bgImg,
                  width: "100%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </Parallax>
            <div className="container mb-5 mb-md-0 pb-5 pb-md-0">
              <div className="row z_index_top">
                <span className="text-uppercase d-block mb-2">{title}</span>
                <h2 className="h1 mt-3 mb-5">
                  <span className={s.mw_33}>{subText}</span>
                </h2>
                <Link
                  to={link}
                  title="Full Case Study"
                  className="ani_grey_arrow color-white"
                >
                  {linkText}
                </Link>
              </div>
            </div>
            <div className={s.full_swiper_mask}></div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default ClientSlider
