import * as React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { ParallaxProvider } from "react-scroll-parallax"
import HomeHero from "../components/home-page/hero"
import ClientLogos from "../components/home-page/client-logos"
import ClientSlider from "../components/home-page/client-slider"
import IndustryBoxes from "../components/home-page/industry-boxes"
import WhatWeDo from "../components/home-page/what-we-do"
import OurCulture from "../components/home-page/our-culture"
import OurThoughts from "../components/shared/our-thoughts"
import UpcomingEvent from "../components/home-page/upcoming-event"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!, $pageId: Int) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        cfHomePage {
          homePageHeroTagline
          homePageHeroByline
          homePageHeroCtaText
          homePageHeroCtaLink
          homePageHeroImage {
            mediaDetails {
              file
            }
          }
          homePageClientLogosIntroPara
          homePageClientsTheLogos {
            homePageClientsAClientLogo {
              altText
              mimeType
              mediaDetails {
                file
              }
            }
          }
          homePageInfoBlockSectionTitle
          homePageInfoBlockSectionLinkText
          homePageInfoBlockSectionIntro
          homePageInfoBlockSectionLinkUrl {
            url
            title
          }
          homePageInfoBlocks {
            homePageInfoBlockIntro
            homePageInfoBlockLinkText
            homePageInfoBlockTitle
            homePageInfoBlockLinkUrl {
              url
              title
            }
            homePageInfoBlockImage {
              altText
              mimeType
              mediaDetails {
                file
              }
            }
          }
          homePageCultureTitle
          homePageCultureIntro
          homePageCultureCtaLinkText
          homePageCultureCtaUrl {
            url
          }
          homePageCultureImage {
            altText
            mediaDetails {
              file
            }
          }
          homePageClientSlides {
            homePageClientLinkText
            homePageClientSubtext
            homePageClientTitle
            homePageClientClientLink {
              url
            }
            homePageClientImage {
              altText
              mediaDetails {
                file
              }
            }
          }
          homePageIndustriesTitle
          homePageIndustriesIntro
          homePageIndustriesSectionLinkTextCopy
          homePageIndustriesSectionLinkUrlCopy {
            url
          }
          homePageInfoBlocksIndustries {
            homePageInfoBlockTitleIndustries
            homePageInfoBlockLinkTextIndustries
            homePageInfoBlockIntroIndustries
            homePageInfoBlockLinkUrlIndustries {
              url
            }
            homePageInfoBlockImageIndustries {
              altText
              mediaDetails {
                file
              }
            }
          }
        }

        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }

      events(where: { status: PUBLISH }) {
        nodes {
          uri
          title
          id
          featuredImage {
            node {
              mediaDetails {
                file
              }
              mimeType
              altText
            }
          }
          events {
            eventsType
            eventsDuration
            eventsDateTime
            eventsPhysicalLocation
            eventsHideInListinngs
          }
        }
      }

      pages(where: { id: $pageId }) {
        nodes {
          cfThoughtsBlock {
            thoughtsBlogItem1 {
              __typename
              ... on WPGraphQL_Post {
                id
                title
                uri
                featuredImage {
                  node {
                    altText
                    mimeType
                    mediaDetails {
                      file
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
const PageTemplateHome = ({ data }) => {
  const page = data.wpgraphql.page
  const thoughts = data.wpgraphql.pages.nodes[0].cfThoughtsBlock
  const upcomingEvents = data.wpgraphql.events.nodes

  return (
    <Layout frontpage="true">
      <main>
        <HomeHero sendata={page.cfHomePage} />

        <ParallaxProvider>
          <WhatWeDo sendata={page.cfHomePage} />
        </ParallaxProvider>

        <ClientLogos sendata={page.cfHomePage} />

        <ParallaxProvider>
          <IndustryBoxes sendata={page.cfHomePage} />
        </ParallaxProvider>

        <ParallaxProvider>
          <ClientSlider sendata={page.cfHomePage} />
        </ParallaxProvider>

        <ParallaxProvider>
          <OurCulture sendata={page.cfHomePage} />
        </ParallaxProvider>

        <UpcomingEvent sendata={upcomingEvents} />

        <div className="pt-5">
          <ParallaxProvider>
            <OurThoughts pagedata={thoughts} />
          </ParallaxProvider>
        </div>
      </main>
    </Layout>
  )
}

export default PageTemplateHome

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
